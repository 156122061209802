import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IubendayPrivacyPolicy from "../components/iubenda-privacy-policy"

const PrivacyPolicyPage = ({ data }) => {
  const { iubenda } = useStaticQuery(
    graphql`
      query {
        iubenda {
          privacyPolicy {
            content
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Privacy Policy"/>
      <IubendayPrivacyPolicy content={iubenda.privacyPolicy.content} />
    </Layout>
  )
}

export default PrivacyPolicyPage